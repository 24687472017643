<template>
	<div class="container">
		<div class="top_header">
			<div class="item" v-for="tab in tabs" :class="{ active: tab.form === tabsIndexName }" @click="tabsIndexName = tab.form" :key="tab.form">
				{{ tab.name }}
			</div>
		</div>
		<customTable v-bind="form[tabsIndexName]" :key="tabsIndexName"></customTable>
	</div>
</template>
<script>
import CustomTable from '@/components/CustomTable';
import { ElMessage } from 'element-plus';
import { getCurrentInstance, reactive, onMounted, ref } from 'vue';
export default {
	name: 'Setting',
	components: {
		CustomTable,
	},
	setup() {
		// 欢迎语
		const { proxy } = getCurrentInstance();
		const identity = localStorage.getItem('identity');
		const tabs = reactive([
			{ name: '欢迎语', form: 'auto_form' },
			{
				name: '快捷回复',
				form: 'question_form',
			},
			{ name: '常见问题', form: 'casual_form' },
		]);
		const tabsIndexName = ref('auto_form');
		const auto_form = {
			api_url: `v3/helpdesk/${identity}/auto/list`,
			columns: [
				{
					prop: 'sortIndex',
					label: '序号',
					attrs: {
						width: 80,
					},
					type: 'sort',
				},
				{
					prop: 'msgContent',
					label: '欢迎语',
					attrs: {
						align: 'left',
						'header-align': 'center',
					},
					formConfig: {
						type: 'input',
						attrs: {
							type: 'textarea',
							maxLength: 100,
							placeholder: '请输入问题，最多100字',
							rows: 3,
						},
						rules: [{ required: true, message: '请输入问题', trigger: 'blur' }],
					},
				},
				{
					prop: 'sort',
					label: '排序',
					attrs: {
						width: 80,
					},
					formConfig: {
						type: 'input',
						attrs: {
							placeholder: '请输入排序值',
						},
						rules: [
							{ required: true, message: '请输入排序', trigger: 'blur' },
							{
								pattern: /^[0-9]+$/,
								message: '请输入数字',
								trigger: 'blur',
							},
						],
					},
				},
				{
					prop: 'isShow',
					label: '默认使用',
					type: 'switch',
					attrs: {
						width: 100,
					},
					switch: {
						'active-value': 1,
						'inactive-value': 0,
					},
					formConfig: {
						type: 'switch',
						defaultValue: 0,
					},
					callback: async (index, record, value) => {
						const url = `v3/helpdesk/${identity}/auto/isShow`;
						const params = {
							autoMsgId: record.autoMsgId,
							isShow: value ? 1 : 0,
						};
						await handleTarget({ url, params });
					},
				},
				{
					prop: 'operate',
					label: '操作',
					type: 'operate',
					attrs: {
						width: 160,
					},
					btn_group: [
						{
							name: '编辑',
							type: 'edit',
							title: '编辑欢迎语',
							url: `v3/helpdesk/${identity}/auto/update`,
						},
						{
							name: '删除',
							popover: {
								title: '确定要删除此内容吗？',
								width: 190,
							},
							className: 'active',
							callback: async (index, record, value) => {
								const url = `v3/helpdesk/${identity}/auto/delete`;
								const params = {
									autoMsgId: record.autoMsgId,
									isShow: value ? 1 : 0,
								};
								await handleTarget({ url, params });
							},
						},
					],
				},
			],
			border: true,
			topBtn: [
				{
					name: '新增欢迎语',
					type: 'add',
					title: '新增欢迎语',
					url: `v3/helpdesk/${identity}/auto/add`,
				},
			],
		};
		const question_form = reactive({
			api_url: `v3/helpdesk/${identity}/quick/list`,
			columns: [
				{
					prop: 'sortIndex',
					label: '序号',
					attrs: {
						width: 80,
					},
					type: 'sort',
				},
				{
					prop: 'msgContent',
					label: '快捷回复语',
					attrs: {
						align: 'left',
						'header-align': 'center',
					},
					formConfig: {
						type: 'input',
						attrs: {
							type: 'textarea',
							maxLength: 100,
							placeholder: '请输入问题，最多100字',
							rows: 3,
						},
						rules: [{ required: true, message: '请输入问题', trigger: 'blur' }],
					},
				},
				{
					prop: 'sort',
					label: '排序',
					attrs: {
						width: 80,
					},
					formConfig: {
						type: 'input',
						attrs: {
							placeholder: '请输入排序值',
						},
						rules: [
							{ required: true, message: '请输入排序', trigger: 'blur' },
							{
								pattern: /^[0-9]+$/,
								message: '请输入数字',
								trigger: 'blur',
							},
						],
					},
				},
				{
					prop: 'isShow',
					label: '默认使用',
					type: 'switch',
					attrs: {
						width: 100,
					},
					switch: {
						'active-value': 1,
						'inactive-value': 0,
					},
					formConfig: {
						type: 'switch',
						defaultValue: 0,
					},
					callback: async (index, record, value) => {
						const url = `v3/helpdesk/${identity}/quick/isShow`;
						const params = {
							quickMsgId: record.quickMsgId,
							isShow: value ? 1 : 0,
						};
						await handleTarget({ url, params });
					},
				},
				{
					prop: 'operate',
					label: '操作',
					type: 'operate',
					attrs: {
						width: 160,
					},
					btn_group: [
						{
							name: '编辑',
							type: 'edit',
							title: '编辑快捷回复',
							url: `v3/helpdesk/${identity}/quick/update`,
						},
						{
							name: '删除',
							popover: {
								title: '确定要删除此内容吗？',
								width: 190,
							},
							className: 'active',
							callback: async (index, record, value) => {
								const url = `v3/helpdesk/${identity}/quick/delete`;
								const params = {
									quickMsgId: record.quickMsgId,
									isShow: value ? 1 : 0,
								};
								await handleTarget({ url, params });
							},
						},
					],
				},
			],
			border: true,
			topBtn: [
				{
					name: '新增快捷回复',
					type: 'add',
					title: '新增快捷回复',
					url: `v3/helpdesk/${identity}/quick/add`,
				},
			],
		});
		const casual_form = {
			api_url: `v3/helpdesk/${identity}/problem/list`,
			columns: [
				{
					prop: 'sortIndex',
					label: '序号',
					attrs: {
						width: 80,
					},
					type: 'sort',
				},
				{
					prop: 'msgContent',
					label: '问题',
					attrs: {
						align: 'left',
						'header-align': 'center',
					},
					formConfig: {
						type: 'input',
						attrs: {
							type: 'textarea',
							maxLength: 100,
							placeholder: '请输入问题，最多100字',
							rows: 3,
						},
						rules: [{ required: true, message: '请输入问题', trigger: 'blur' }],
					},
				},
				{
					prop: 'msgReply',
					label: '回复',
					attrs: {
						align: 'left',
						'header-align': 'center',
					},
					formConfig: {
						type: 'input',
						attrs: {
							type: 'textarea',
							maxLength: 300,
							placeholder: '请输入回复，最多300字',
							rows: 3,
						},
						rules: [{ required: true, message: '请输入回复', trigger: 'blur' }],
					},
				},
				{
					prop: 'sort',
					label: '排序',
					attrs: {
						width: 80,
					},
					formConfig: {
						type: 'input',
						attrs: {
							placeholder: '请输入排序值',
						},
						rules: [
							{ required: true, message: '请输入排序', trigger: 'blur' },
							{
								pattern: /^[0-9]+$/,
								message: '请输入数字',
								trigger: 'blur',
							},
						],
					},
				},
				{
					prop: 'isShow',
					label: '显示',
					type: 'switch',
					attrs: {
						width: 80,
					},
					switch: {
						'active-value': 1,
						'inactive-value': 0,
					},
					formConfig: {
						type: 'switch',
						defaultValue: 0,
					},
					callback: async (index, record, value) => {
						const url = `v3/helpdesk/${identity}/problem/isShow`;
						const params = {
							problemMsgId: record.problemMsgId,
							isShow: value ? 1 : 0,
						};
						await handleTarget({ url, params });
					},
				},
				{
					prop: 'operate',
					label: '操作',
					type: 'operate',
					attrs: {
						width: 160,
					},
					btn_group: [
						{
							name: '编辑',
							type: 'edit',
							title: '编辑常见问题',
							url: `v3/helpdesk/${identity}/problem/update`,
						},
						{
							name: '删除',
							popover: {
								title: '确定要删除此内容吗？',
								width: 190,
							},
							className: 'active',
							callback: async (index, record) => {
								const url = `v3/helpdesk/${identity}/problem/delete`;
								const params = {
									problemMsgId: record.problemMsgId,
								};
								await handleTarget({ url, params });
							},
						},
					],
				},
			],
			border: true,
			topBtn: [
				{
					name: '新增常见问题',
					type: 'add',
					title: '新增常见问题',
					url: `v3/helpdesk/${identity}/problem/add`,
				},
			],
		};
		const form = reactive({
			auto_form,
			question_form,
			casual_form,
		});
		// 操作
		const handleTarget = ({ method: method = '$post', url, params }) => {
			return new Promise((resolve, reject) => {
				proxy[method](url, params).then((res) => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						resolve(res);
					} else {
						ElMessage.error(res.msg);
						reject(false);
					}
				});
			});
		};
		onMounted(() => {});
		return {
			tabs,
			form,
			handleTarget,
			tabsIndexName,
		};
	},
};
</script>
<style lang="scss" scoped>
.container {
	width: 820px;
	height: 810px;
	margin: auto;
}
.top_header {
	display: flex;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	.item {
		transform: translateY(1px);
		cursor: pointer;
		background-color: #e7e7e7;
		border: 1px solid #b8b8b8;
		&.active {
			border-bottom: none;
			background-color: #fff;
		}
		border-radius: 10px 10px 0 0;
		padding: 0 20px;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
	}
}
:deep(.table_container) {
	border-radius: 0 10px 10px 10px;
}
</style>
